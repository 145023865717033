// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.videos {
  /* background-image: url("https://images.unsplash.com/photo-1478760329108-5c3ed9d495a0?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"); */
}
.text-videos {
  margin-top: 80px;
}

/* .btn-videos {
  margin: 0px; 
  position: relative;
  top: 50%;
  left: 45%;
} */


@media (max-width: 991px) {
  /* .btn-videos {
    left: 43%;
  } */
}

@media (max-width: 645px) {
  .videos iframe {
    width: 400px;
    height: auto;
  }

  /* .btn-videos {
    left: 39%;
  } */

  /* .thumbnail-image {
    max-width: 60%;
  } */
}

@media (max-width: 490px) {
  .videos iframe {
    width: 290px;
    height: auto;
  }

  /* .btn-videos {
    left: 37%;
  } */
}


@media (max-width: 435px) {
  /* .btn-videos {
    left: 34%;
  } */
}`, "",{"version":3,"sources":["webpack://./src/Assets/Css/videos.css"],"names":[],"mappings":";;AAEA;EACE,qMAAqM;AACvM;AACA;EACE,gBAAgB;AAClB;;AAEA;;;;;GAKG;;;AAGH;EACE;;KAEG;AACL;;AAEA;EACE;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;;KAEG;;EAEH;;KAEG;AACL;;AAEA;EACE;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;;KAEG;AACL;;;AAGA;EACE;;KAEG;AACL","sourcesContent":["\n\n.videos {\n  /* background-image: url(\"https://images.unsplash.com/photo-1478760329108-5c3ed9d495a0?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D\"); */\n}\n.text-videos {\n  margin-top: 80px;\n}\n\n/* .btn-videos {\n  margin: 0px; \n  position: relative;\n  top: 50%;\n  left: 45%;\n} */\n\n\n@media (max-width: 991px) {\n  /* .btn-videos {\n    left: 43%;\n  } */\n}\n\n@media (max-width: 645px) {\n  .videos iframe {\n    width: 400px;\n    height: auto;\n  }\n\n  /* .btn-videos {\n    left: 39%;\n  } */\n\n  /* .thumbnail-image {\n    max-width: 60%;\n  } */\n}\n\n@media (max-width: 490px) {\n  .videos iframe {\n    width: 290px;\n    height: auto;\n  }\n\n  /* .btn-videos {\n    left: 37%;\n  } */\n}\n\n\n@media (max-width: 435px) {\n  /* .btn-videos {\n    left: 34%;\n  } */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
