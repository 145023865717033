import React from "react";
import Card from "../../Pages/tvPage/Card";
import { Col, Row, Button, Container } from 'react-bootstrap';
import heroImg from '../../Assets/Img/concert.jpg'
import heroImg1 from '../../Assets/Img/concert1.jpg'
import heroImg2 from '../../Assets/Img/concert2.jpg'
import heroImg3 from '../../Assets/Img/concert3.jpg'
import '../../Assets/Css/videos.css'
import { Link } from "react-router-dom";


const Videos = () => {

  const videoData = [
    { videoUrl: "https://www.youtube.com/embed/e8EGEW7cCmA?si=SzqoRgvekYKcGVn4", heroImg: heroImg, videoTitle: "Episode 1: Lorem Ipsum" },
    { videoUrl: "https://www.youtube.com/embed/AW3mxC6OsyI?si=rkQBBnYfzXH3fVTv", heroImg: heroImg1, videoTitle: "Episode 2: Dolor Sit Amet" },
    { videoUrl: "https://www.youtube.com/embed/ZL-VcS_cOLU?si=9LQiHFaMZK_IPgCK", heroImg: heroImg2, videoTitle: "Episode 3: Consectetur Adipiscing" },

  ];

  return (
    <Container fluid>
      <section className='videos mt-4' >
        <p className="text-videos fs-1 fw-bold ms-4">Find out more from Videos</p>
        <Container fluid className="container-oztv">
        {videoData.map((video, index) => (
            <Card 
            key={index} 
            videoUrl={video.videoUrl} 
            thumbnailUrl={video.heroImg} 
            videoTitle={video.videoTitle} 
            />
          ))}
        </Container>
        <div className="text-center">
            <Link to="/oztv">
              <Button 
                variant="outline-dark" 
                className="btn-videos rounded-pill my-5">
                    View All Videos
                </Button>
            </Link>
          </div>
      </section>
    </Container>
  )
}

export default Videos