// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interview-page-container {
  margin: 100px 0px;
}



/* .background {
  background: linear-gradient(#e66465, #9198e5);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.scrollText {
  font-size: 1.5rem;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  height: 10rem;
  width: 25%;
  text-align: center;
  border-radius: 10px;
}

.parallax {
  margin-right: 15%;
}

.sticky {
  margin-left: 15%;
  background-color: #ff6d6d;
}

.blue {
  background-color: #5b65ad;
  color: white;
}

.purple {
  background-color: #9d65d0;
} */

.text-interview {
  margin-top: 50px !important;
}


@media (max-width: 800px) {
  .card {
    width: 40%;
  }

  .parallax {
    margin-right: 1.5rem;
  }

  .sticky {
    margin-left: 1.5rem;
  }

  .about-section-text-container{
    margin-top: 150px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/interviewPage/interviewPage.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;;;AAIA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA0CG;;AAEH;EACE,2BAA2B;AAC7B;;;AAGA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".interview-page-container {\n  margin: 100px 0px;\n}\n\n\n\n/* .background {\n  background: linear-gradient(#e66465, #9198e5);\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n}\n\n.scrollText {\n  font-size: 1.5rem;\n}\n\n.card {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1.25rem;\n  height: 10rem;\n  width: 25%;\n  text-align: center;\n  border-radius: 10px;\n}\n\n.parallax {\n  margin-right: 15%;\n}\n\n.sticky {\n  margin-left: 15%;\n  background-color: #ff6d6d;\n}\n\n.blue {\n  background-color: #5b65ad;\n  color: white;\n}\n\n.purple {\n  background-color: #9d65d0;\n} */\n\n.text-interview {\n  margin-top: 50px !important;\n}\n\n\n@media (max-width: 800px) {\n  .card {\n    width: 40%;\n  }\n\n  .parallax {\n    margin-right: 1.5rem;\n  }\n\n  .sticky {\n    margin-left: 1.5rem;\n  }\n\n  .about-section-text-container{\n    margin-top: 150px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
