import React from "react";
import { Link, useParams } from "react-router-dom";
import danilla from "../../Assets/Img/danilla_interview.jpg";
import rimba from "../../Assets/Img/rimba_interview.jpg";
import { Col, Row } from "react-bootstrap";

const CardlistRadio = () => {

  const { category } = useParams();
  
  const RadioData = [
    {
      category: "streaming-archive",
      image: danilla,
      title: "Devana Kinta",
      paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    },
    {
      category: "podcast-01",
      image: rimba,
      title: "Billa Darima",
      paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    },
  ];

  const filteredRadioData = category ? RadioData.filter(radio => radio.category === category) : RadioData;

  return (
    <>
      <section className="cardlist-radio">
        <div className="container-fluid">
        {filteredRadioData.map((data, index) => (
            <Row key={index} className="g-5 mb-5">
              <Col lg={2} md={12} sm={12} className="">
                <img
                  src={data.image}
                  width={300}
                  height="auto"
                  alt=""
                  loading="lazy"
                  className="d-block mx-auto img-fluid ms-4"
                />
              </Col>
              <Col lg={10} md={12} sm={12}>
                <div className="text-radio">
                  <h3 className="fw-bold"><Link to={`/${category}`}>{data.title}</Link></h3>
                  <p>{data.paragraph}</p>
                </div>
              </Col>
            </Row>
          ))}
                {/* {RadioData.map((data,index) => (
                      <Row className="g-5 mb-5" key={index}>
                      <Col lg={2} md={12} sm={12} className="d-flex flex-column align-items-center">
                        <div className="img-container ms-4">
                          <img 
                            src={data.image} 
                            width={300}
                            height="auto"
                            alt="" 
                            loading="lazy" 
                            className="d-block mx-auto img-fluid img-archive-radio" 
                          />
                          <div className="listen-data">
                            <Link to={"/single-event"} className="text-white">LISTEN NOW</Link>
                          </div>
                        </div>
                      </Col>

                    <Col lg={10} md={12} sm={12}>
                          <div className="text-news">
                            <Link><h3 className="fw-bold">{data.title}</h3></Link>
                            <p>{data.paragraph}</p>
                          </div>
                      </Col>
                </Row>
                ))} */}
        </div>
      </section>
    </>
  );
};

export default CardlistRadio;
