import React from "react";
import styled from "styled-components";
import Card from "./Card";
import SinglePageSlider from "../singlePages/slider/singlePageSlider";
import heroImg from '../../Assets/Img/concert.jpg'
import heroImg1 from '../../Assets/Img/concert1.jpg'
import heroImg2 from '../../Assets/Img/concert2.jpg'
import heroImg3 from '../../Assets/Img/concert3.jpg'
import { Link } from "react-router-dom";
import './oztv.css';
import { Container } from "react-bootstrap";

// const Container = styled.div`
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   margin-left: 10px;
//   margin-top: 150px;
// `;

const OZTV = () => {
  
  
  const videoData = [
    { videoUrl: "https://www.youtube.com/embed/e8EGEW7cCmA?si=SzqoRgvekYKcGVn4", heroImg: heroImg, videoTitle: "Episode 1: Lorem Ipsum" },
    { videoUrl: "https://www.youtube.com/embed/AW3mxC6OsyI?si=rkQBBnYfzXH3fVTv", heroImg: heroImg1, videoTitle: "Episode 2: Dolor Sit Amet" },
    { videoUrl: "https://www.youtube.com/embed/ZL-VcS_cOLU?si=9LQiHFaMZK_IPgCK", heroImg: heroImg2, videoTitle: "Episode 3: Consectetur Adipiscing" },
    { videoUrl: "https://www.youtube.com/embed/e7IsWhnNvVE?si=OtVcg-lAkmBEEjnl", heroImg: heroImg1, videoTitle: "Episode 4: Sed turpis tincidunt id aliquet" },
    { videoUrl: "https://www.youtube.com/embed/I5LYTWsXOqg?si=PxrPNBaK2imkXWJw", heroImg: heroImg, videoTitle: "Episode 5: Velit laoreet id donec" },
    // Add more objects as needed
  ];

  
  return (
    <Container fluid className="container-oztv" style={{ marginTop:"150px" }}>
      <h1 className="display-4 mx-auto">JUST LAUNCHED</h1>
      <p>Just Launched OZ regularly hosts both local and international artists for in-studio performances 
        in our live room, concerts in the Gathering Space, and live broadcasts from music festivals. 
        Not only can you enjoy these performances on the air, but you can also relive the experience 
        with our live videos. Subscribe to the <Link to={"https://www.youtube.com/hashtag/ozclusive"} className="fw-bold" target="_blank">OZ Youtube channel</Link> for more great live sessions like 
        these. And check out some of the recent performances from our Seattle Center studios below.</p>
    {videoData.map((video, index) => (
            <Card 
            key={index} 
            videoUrl={video.videoUrl} 
            thumbnailUrl={video.heroImg} 
            videoTitle={video.videoTitle} 
            />
          ))}
    </Container>
  );
};

export default OZTV;
