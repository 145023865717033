// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-event {
  margin-top: 100px;
}

.breadcrumb-item a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 0%;
  position: absolute;
  background: rgb(252,186,51);
  background: linear-gradient(90deg, rgba(252,186,51,1) 0%, rgba(33,138,89,1) 100%);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
 
.breadcrumb-item a:hover:after { 
  width: 100%; 
  left: 0; 
}

.breadcrumb-item a{
  color: var(--text-color) !important;
}

@media (max-width:768px) {
  .single-event {
    margin-bottom: 500px;
  }
}

@media (max-width:444px) {
  .single-event {
    margin-bottom: 700px;
  }
}

@media (max-width:390px) {
  .single-event {
    margin-bottom: 900px;
  }
}

@media (max-width:364px) {
  .single-event {
    margin-bottom: 1200px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/eventsPage/eventSinglePage/eventSinglePage.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,8CAA8C;EAC9C,SAAS;EACT,WAAW;EACX,cAAc;EACd,WAAW;EACX,QAAQ;EACR,kBAAkB;EAClB,2BAA2B;EAC3B,iFAAiF;EACjF,iDAAiD;EACjD,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,OAAO;AACT;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF;;AAEA;EACE;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".single-event {\n  margin-top: 100px;\n}\n\n.breadcrumb-item a:after {    \n  background: none repeat scroll 0 0 transparent;\n  bottom: 0;\n  content: \"\";\n  display: block;\n  height: 2px;\n  left: 0%;\n  position: absolute;\n  background: rgb(252,186,51);\n  background: linear-gradient(90deg, rgba(252,186,51,1) 0%, rgba(33,138,89,1) 100%);\n  transition: width 0.3s ease 0s, left 0.3s ease 0s;\n  width: 0;\n}\n \n.breadcrumb-item a:hover:after { \n  width: 100%; \n  left: 0; \n}\n\n.breadcrumb-item a{\n  color: var(--text-color) !important;\n}\n\n@media (max-width:768px) {\n  .single-event {\n    margin-bottom: 500px;\n  }\n}\n\n@media (max-width:444px) {\n  .single-event {\n    margin-bottom: 700px;\n  }\n}\n\n@media (max-width:390px) {\n  .single-event {\n    margin-bottom: 900px;\n  }\n}\n\n@media (max-width:364px) {\n  .single-event {\n    margin-bottom: 1200px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
