// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.singlePopular {
  overflow: hidden;
  margin-top: 100px;
}
.singlePopular .box {
  width: 150px;
  height: 150px;
  position: relative;
  cursor: pointer;
}
.singlePopular img {
  width: 210px;
  height: 100px;
  border-radius: 5px;
}
.singlePopular .text {
  position: absolute;
  bottom: 30px;
  left: 0;
  padding: 10px;
  color: #fff;
}
.singlePopular .text h1 {
  font-size: 11px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/singlePages/slider/singlePageSlider.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,OAAO;EACP,aAAa;EACb,WAAW;AACb;AACA;EACE,eAAe;AACjB","sourcesContent":[".singlePopular {\n  overflow: hidden;\n  margin-top: 100px;\n}\n.singlePopular .box {\n  width: 150px;\n  height: 150px;\n  position: relative;\n  cursor: pointer;\n}\n.singlePopular img {\n  width: 210px;\n  height: 100px;\n  border-radius: 5px;\n}\n.singlePopular .text {\n  position: absolute;\n  bottom: 30px;\n  left: 0;\n  padding: 10px;\n  color: #fff;\n}\n.singlePopular .text h1 {\n  font-size: 11px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
